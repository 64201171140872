import { apiSlice } from "../api-slice";
import { Tag } from "../types";
import { CreateIntroPageAttributes, IntroPage } from "./types";

export const pageApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getIntroPage: builder.query<IntroPage, string | number>({
      query: (pageId) => ({
        url: `instructor/topic/page/${pageId}`,
        timeout: 300000,
      }),
      providesTags: (result, error, pageId) => [
        { type: Tag.INTRO_PAGE, id: pageId },
      ],
    }),
    updateIntroPage: builder.mutation<IntroPage, Partial<IntroPage>>({
      query(data) {
        const { id, ...body } = data;
        return {
          url: `instructor/topic/page/${id}`,
          method: "PUT",
          body,
          timeout: 300000,
        };
      },
      transformResponse(baseQueryReturnValue: { data: IntroPage }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: (result, error, page) => [
        { type: Tag.INTRO_PAGE, id: page.id },
      ],
    }),
    createIntroPage: builder.mutation<IntroPage, CreateIntroPageAttributes>({
      query(data) {
        return {
          url: `instructor/topic/page`,
          method: "POST",
          body: data,
        };
      },
      transformResponse(baseQueryReturnValue: { data: IntroPage }, meta, arg) {
        return baseQueryReturnValue.data;
      },
      invalidatesTags: [Tag.INTRO_PAGE],
    }),
    deleteIntroPage: builder.mutation<IntroPage, number | string>({
      query: (pageId) => ({
        url: `instructor/topic/page/${pageId}`,
        method: "DELETE",
      }),
      invalidatesTags: [Tag.INTRO_PAGE],
    }),
  }),
});

export const {
  useGetIntroPageQuery,
  useUpdateIntroPageMutation,
  useCreateIntroPageMutation,
  useDeleteIntroPageMutation,
} = pageApiSlice;
