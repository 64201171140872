import { Route, Routes } from "react-router-dom";
import { useGetMeQuery } from "../features/api/user-api";
import ProfessorManager from "./ProfessorManager";
import AdminManager from "./AdminManager";
import SuperProfessorManager from "./SuperProfessorManager";
import StudentManager from "./StudentManager";

export default function PrivateManager() {
  const { data: user, isError, isSuccess, isLoading, error } = useGetMeQuery();

  if (isSuccess) {
    const { roles } = user.user;
    const profile_picture_url = user.profile?.profile_picture_url? user.profile?.profile_picture_url : "";
    const roleManager = roles.includes("ROLE_ADMIN") ? (
      <AdminManager userIcon={profile_picture_url} />
    ) : roles.includes("ROLE_SUPER_PROFESSOR") ? (
      <SuperProfessorManager userIcon={profile_picture_url} />
    ) : roles.includes("ROLE_PROFESSOR") ? (
      <ProfessorManager userIcon={profile_picture_url} />
    ) : (
      <StudentManager userIcon={profile_picture_url} />
    );

    return (
      <Routes>
        <Route path="*" element={roleManager} />
      </Routes>
    );
  }

  if (isLoading) {
    return <p>Loading...</p>;
  }

  return <p>Unexpected error. Please try later.</p>;
}
