import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import AssignmentManager from "./Professor/Assignment/Manager";
import CourseManager from "./Professor/Course/Manager";
import CourseTemplateManager from "./Professor/CourseTemplate/Manager";
import ProfessorDashboard from "./Professor/Dashboard/ProfDashboard";
import ExpressionTest from "./Professor/ExpressionTest/ExpressionTests";
import TopicManager from "./Professor/Topic/Manager";
import { EntryType, LeftNavEntries } from "../components/Navbar/LeftSideNav/LeftSidePanel";
import CommonRouters from "./CommonRouters";
import UserManager from "./Professor/User/UserManager";

export default function ProfessorManager({ userIcon }: { userIcon?: string }) {
  const navEntries: LeftNavEntries[] = [
    {
      type: EntryType.LINK,
      title: "Courses",
      link: "/prof/course",
    },
    {
      type: EntryType.LINK,
      title: "Users",
      link: "/user",
    },
    {
      type: EntryType.LINK,
      title: "Math Playground",
      link: "math-expression-test",
    },
  ];

  return (
    <>
      <Navbar leftNavEntries={navEntries} userIcon={userIcon} />
      <CommonRouters />
      <Routes>
        <Route path="dashboard" element={<ProfessorDashboard />} />
        <Route path="math-expression-test" element={<ExpressionTest />} />
        <Route path="/prof/topic/*" element={<TopicManager />} />
        <Route
          path="/prof/course/*"
          element={<CourseManager canAddStudent canRemoveStudent canCreateAssignment />}
        />
        <Route path="/prof/course-template/*" element={<CourseTemplateManager />} />
        <Route path="/prof/assignment/*" element={<AssignmentManager />} />
        <Route path="/user/*" element={<UserManager />} />
      </Routes>
    </>
  );
}
