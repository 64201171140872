import { RefObject, forwardRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import "./TextEditor.css";

const modules = {
    toolbar: [
        ['image'],
    ],
};

const formats = [
    'image',
];

interface OnChangeHandler {
    (e: any): void;
}

type Props = {
    initialValue: string;
    onChange: OnChangeHandler;
    reference: RefObject<ReactQuill>;
};

const options = {
    displayHistory: true,
    historyCacheKey: "__texteditor_picture_history_cachekey_",
    historySize: 10,
    formats: formats
};

function TextEditor({ initialValue, onChange, reference }: Props, ref: any) {

    useEffect(() => {
        if (reference && reference.current && reference.current.editor) {
            reference.current.editor.getModule("toolbar");
        } else {
            console.error("reference editor not set!");
        }
    }, [reference]);

    return (
        <>
            <ReactQuill
                ref={reference}
                theme="snow"
                defaultValue={initialValue ? initialValue : ""}
                modules={modules}
                formats={formats}
                onChange={onChange}
            />
        </>
    );
};

export default forwardRef(TextEditor);