import { Button, message, Modal, Space, Upload, UploadFile } from "antd";
import { DownloadOutlined, InboxOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useAddCourseStudentsFromFileMutation } from "../../../features/api/professor/course";
import { RcFile } from "antd/lib/upload";
import downloadAndSaveFile from "../../../features/api/save-file";
import { API_URL } from "../../../features/api/api-url";

export interface AddStudentFileProps {
  courseId: number;
  isOpen: boolean;
  onCancel: () => void;
  onOk: () => void;
}

export default function AddStudentFile({ courseId, isOpen, onCancel, onOk }: AddStudentFileProps) {
  const [messageApi, contextHolder] = message.useMessage();
  const [file, setFile] = useState<UploadFile | null>();
  const [enrollStudents, { isLoading: isEnrolling }] = useAddCourseStudentsFromFileMutation();

  const handleCancel = () => {
    setFile(null);
    onCancel();
  };

  const handleUpload = () => {
    if (!file) {
      return;
    }

    enrollStudents({ courseId, data: file as RcFile })
      .unwrap()
      .then(({ success, failed }) => {
        if (success.length > 0) {
          messageApi.success(`Added ${success.length} students`);
        }
        if (failed.length > 0) {
          messageApi.warning(`Failed to add ${failed.length} students`);
        }
        setFile(null);
        onOk();
      })
      .catch(() => {
        messageApi.error("Failed to add students");
      });
  };

  const handleExampleDownload = () => {
    downloadAndSaveFile({
      url: `${API_URL}/instructor/course/${courseId}/student/csv/example`,
      defaultFileName: "student-roster-example.csv",
    }).then((result) => {
      if (!result.success) {
        messageApi.error("Unable to download example");
      }
    });
  };

  return (
    <>
      {contextHolder}
      <Modal
        open={isOpen}
        title="Add students"
        cancelText="Cancel"
        okText="Upload"
        onCancel={handleCancel}
        onOk={handleUpload}
        confirmLoading={isEnrolling}
        okButtonProps={{
          disabled: file == null,
        }}
      >
        <Space direction="vertical" size="middle" style={{ width: "100%" }}>
          <Button
            type="primary"
            icon={<DownloadOutlined rev={undefined} />}
            onClick={handleExampleDownload}
          >
            Example File
          </Button>
          <Upload.Dragger
            name="student_list_upload"
            fileList={file ? [file] : []}
            onRemove={() => {
              setFile(null);
            }}
            beforeUpload={(file) => {
              const isCSV = file.type === "text/csv"  || file.type === "text/x-csv" || file.type === "application/vnd.ms-excel" || file.type === "application/csv" || file.type === "application/x-csv" || file.type === "text/comma-separated-values";
              if (isCSV) {
                setFile(file);
              } else {
                message.error("Please upload CSV file");
              }

              return false;
            }}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined rev={undefined} />
            </p>
            <p className="ant-upload-text">Click or drag CSV file to this area to upload</p>
          </Upload.Dragger>
        </Space>
      </Modal>
    </>
  );
}
