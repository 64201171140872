import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { selectRightNav } from "../../../features/nav/nav-selectors";
import { hideRightSidebar, toggleRightSidebar } from "../../../features/nav/nav-slice";
import { ClickAwayListener } from "@mui/base";
import { UserIcon, WhiteCircle } from "../style";
import RightSidePanel from "./RightSidePanel";
import ReactQuill from "react-quill";

export default function RightSideNav({ userIcon }: { userIcon?: string }) {
  const dispatch = useAppDispatch();
  const isActive = useAppSelector((state) => selectRightNav(state));

  const closeSidebar = () => {
    dispatch(hideRightSidebar());
  };

  const toggleSidebar = () => {
    dispatch(toggleRightSidebar());
  };

  return (
    <ClickAwayListener onClickAway={closeSidebar}>
      <div style={{ margin: "" }}>
        {(userIcon && userIcon !== "/images/default-profile.png") && (
          <WhiteCircle onClick={toggleSidebar}>
            <ReactQuill
              value={userIcon}
              readOnly={true}
              theme={"bubble"}
            />
          </WhiteCircle>
        )}
        {(!userIcon || userIcon == "/images/default-profile.png") && (<UserIcon onClick={toggleSidebar} />)}
        {isActive && <RightSidePanel onEntryClick={closeSidebar} />}
      </div>
    </ClickAwayListener>
  );
}
