import { RefObject, forwardRef, useEffect } from 'react';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';

// KaTeX dependency
import katex from "katex";
import "katex/dist/katex.css";

// MathQuill dependency
import "mathquill/build/mathquill.css";
import "mathquill/build/mathquill.js";

// mathquill4quill include
import "mathquill4quill/mathquill4quill.css";
import "./TextEditor.css";

const mathquill4quill = require("mathquill4quill/mathquill4quill.js");
import htmlEditButton from "quill-html-edit-button";
import { imageHandler, CustomImageHandler } from '../../../../features/api/save-file';

const colors = ["red", "green", "blue", "orange", "violet"]
const highlight = [" #ffb7b7", "#a8d1ff", "#fff2a8", " #E0BBE4", "#8ABF96"]

const CUSTOM_OPERATORS = [
    ["\\pm", "\\pm"],
    ["\\sqrt{x}", "\\sqrt"],
    ["\\sqrt[3]{x}", "\\sqrt[3]{}"],
    ["\\sqrt[n]{x}", "\\nthroot"],
    ["\\frac{x}{y}", "\\frac"],
    ["x^2", "^2"],
    ["x^n", "^{}"],
    // ["\\sum^{s}_{x}{d}", "\\sum"],
    // ["\\prod^{s}_{x}{d}", "\\prod"],
    // ["\\coprod^{s}_{x}{d}", "\\coprod"],
    // ["\\int^{s}_{x}{d}", "\\int"],
    // ["\\binom{n}{k}", "\\binom"]
];

const modules = {
    formula: true,
    toolbar: {
        container:[
        [
            { font: [false, 'serif', 'monospace'] },
        ], [
            { size: ['small', false, 'large', 'huge'] },
        ],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { color: colors },
            { background: highlight }
        ],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
        ],
        [
            { align: '' },
            { align: 'right' },
            { align: 'center' },
            { align: 'justify' }
        ],
        ['link', 'image', 'formula'],
    ],
},
    htmlEditButton: {
        debug: true, // logging, default:false
        msg: "Edit the content in HTML format", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
        okText: "Ok", // Text to display in the OK button, default: Ok,
        cancelText: "Cancel", // Text to display in the cancel button, default: Cancel
        buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
        buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
        syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
        prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
        editorModules: {} // The default mod
    },
};

const formats = [
    'font',
    'size',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'color',
    'background',
    'list',
    'indent',
    'align',
    'link',
    'image',
    'formula'
];

Quill.register({
    "modules/htmlEditButton": htmlEditButton,
})

interface OnChangeHandler {
    (e: any): void;
}

type Props = {
    initialValue: string;
    onChange: OnChangeHandler;
    reference: RefObject<ReactQuill>;
};

const options = {
    displayHistory: true,
    historyCacheKey: "__texteditor_math_history_cachekey_",
    historySize: 10,
    operators: CUSTOM_OPERATORS,
    // formats: formats,
};

function TextEditor({ initialValue, onChange, reference }: Props, ref: any) {
    useEffect(() => {
        if (reference && reference.current && reference.current.editor) {
            const enableMathQuillFormulaAuthoring = mathquill4quill({ Quill, katex });
            enableMathQuillFormulaAuthoring(reference.current.editor, options);
            reference.current.editor.getModule("toolbar").addHandler("image",  () => CustomImageHandler(reference.current?.editor));
        } else {
            console.error("reference editor not set!");
        }
    }, [reference]);

    return (
        <>
            <ReactQuill
                ref={reference}
                theme="snow"
                defaultValue={initialValue || ''}
                modules={modules}
                formats={formats}
                onChange={onChange}
            />
        </>
    );
};

export default forwardRef(TextEditor);