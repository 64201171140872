import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import AssignmentPage from "./Student/Assignment";
import StudDashboard from "./Student/Dashboard/StudDashboard";
import { EntryType, LeftNavEntries } from "../components/Navbar/LeftSideNav/LeftSidePanel";
import StudAssignments from "./Student/Assignment/StudAssignments";
import StudCourses from "./Student/Course/StudCourses";
import CommonRouters from "./CommonRouters";

export default function StudentManager({ userIcon }: { userIcon?: string }) {
  const navEntries: LeftNavEntries[] = [
    {
      type: EntryType.LINK,
      title: "Courses",
      link: "/stud/courses",
    },
    {
      type: EntryType.LINK,
      title: "Assignments",
      link: "/stud/assignments",
    },
  ];

  return (
    <>
      <Navbar leftNavEntries={navEntries} userIcon={userIcon} />
      <CommonRouters />
      <Routes>
        <Route path="dashboard" element={<StudDashboard />} />
        <Route path="stud/topic/:topicId/*" element={<AssignmentPage />} />
        <Route path="stud/courses" element={<StudCourses />} />
        <Route path="stud/assignments" element={<StudAssignments />} />
        <Route path="stud/assignments/course/:courseId" element={<StudAssignments />} />
      </Routes>
    </>
  );
}
