import { Form, message, Modal, Select } from "antd";
import React from "react";
import { ProfileForm, OutputFormData, InputFormData } from "./ProfileForm";

export interface ModalUserEditProps {
  open: boolean;
  initialValues?: Partial<InputFormData>;
  onSubmit: (values: InputFormData) => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ModalProfileEdit({
  open,
  initialValues,
  onSubmit,
  onCancel,
  isLoading,
}: ModalUserEditProps) {
  const [form] = Form.useForm<OutputFormData>();

  const handleSubmit = async () => {
    try {
      const formValues = await form.validateFields();
      onSubmit({
        firstname: formValues.firstname.trim(),
        lastname: formValues.lastname.trim(),
        // email: formValues.email.trim(),
        user_bio: formValues.user_bio.trim(),
        profile_picture_url: formValues.profile_picture_url.trim(),
      });
      form.resetFields();
    } catch (info) {
      console.log("Validation Failed:", info);
    }
  };

  return (
    <Modal
      open={open}
      title="Edit Profile"
      okText="Save"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <ProfileForm
        form={form}
        initialValues={
          initialValues && {
            firstname: initialValues.firstname,
            lastname: initialValues.lastname,
            // email: initialValues.email,
            user_bio: initialValues.user_bio,
            profile_picture_url: initialValues.profile_picture_url,
          }
        }
      />
    </Modal>
  );
}