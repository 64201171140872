import { Button, message } from "antd";
import { useState } from "react";
import defaultUserImage from "../../components/user-image-default.png";
import { useGetMeQuery, useUpdateUserMutation } from "../../features/api/user-api";
import { FieldContent, FieldHeader, ProfileContainer, ProfileContent, ProfileDiv, ProfileField, ProfileFields, ProfileHeader, ProfileImageStyle } from "./style";
// import { useProfileContext } from "./Context";
import { DeleteOutlined, EditFilled, PlusOutlined } from "@ant-design/icons";
import { ModalProfileEdit } from "./ModalProfileEdit";
import ReactQuill from "react-quill";

export function Profile() {
  const { data: user, isLoading, isError, isSuccess } = useGetMeQuery();
  // const { canEdit } = useProfileContext();
  const [messageApi, contextHolder] = message.useMessage();
  const [updateProfile, { isLoading: isUpdating }] = useUpdateUserMutation();
  const [isEdit, setIsEdit] = useState(false);

  if (isLoading) {
    return (
      <>
        <ProfileDiv>
          <p>is loading...</p>
        </ProfileDiv>
      </>
    );
  }
  if (isSuccess) {
    return (
      <>
        <ProfileContainer>
          <ProfileHeader>Profile</ProfileHeader>
          <ProfileContent>
            {(user.profile?.profile_picture_url && user.profile?.profile_picture_url !== "/images/default-profile.png") && (
              <div dangerouslySetInnerHTML={{ __html: user.profile?.profile_picture_url }}></div>
            )}
            {(!user.profile?.profile_picture_url || user.profile?.profile_picture_url == "/images/default-profile.png") && (
              <ProfileImageStyle src={defaultUserImage} alt="User profile image." />
            )}
            <ProfileFields>
              <ProfileField><FieldHeader>First Name:</FieldHeader><FieldContent>{user.profile?.firstname}</FieldContent></ProfileField>
              <ProfileField><FieldHeader>Lastname:</FieldHeader><FieldContent>{user.profile?.lastname}</FieldContent></ProfileField>
              <ProfileField><FieldHeader>Email:</FieldHeader><FieldContent>{user.user.email}</FieldContent></ProfileField>
              <ProfileField><FieldHeader>Bio:</FieldHeader><FieldContent>{user.profile?.user_bio}</FieldContent></ProfileField>
            </ProfileFields>
          </ProfileContent>
          {true && (
            <Button
              type="primary"
              icon={<EditFilled rev={undefined} />}
              onClick={() => setIsEdit(true)}
            >
              Edit
            </Button>
          )}
          <ModalProfileEdit
            open={isEdit}
            initialValues={user.profile}
            onSubmit={(values) => {
              updateProfile({
                ...values,
                id: user.user.id,
                profile_picture_url: values.profile_picture_url,
              })
                .unwrap()
                .then(() => {
                  setIsEdit(false);
                  messageApi.success("Topic created");
                })
                .catch(() => {
                  messageApi.error("Failed to create topic");
                });
            }}
            onCancel={() => setIsEdit(false)}
            isLoading={isUpdating}
          />
        </ProfileContainer>
      </>
    );
  }

  return (
    <div>
      <h5>Unknown error</h5>
    </div>
  );
}
