import PageHeader from "../../components/PageHeader";
import Courses from "../Professor/Dashboard/Courses";
import Topics from "../Professor/Dashboard/Topics";

export default function AdminDashboard() {
  return (
    <>
      <PageHeader header="Admin Dashboard" subheader="" />
      <br />
      {/* ------------- Display Course ------------- */}
      <Courses />
      <br />

      <Topics />
      <br />

      {/* ------------- Display Assignment ------------- */}

      {/* <Assignments /> */}
      {/* <br /> */}
    </>
  );
}
