import { RefObject, useRef } from "react";
import { store } from "../../app/store";
import ReactQuill from "react-quill";
import { API_URL } from "./api-url";
import { Quill } from "quill";

function getFileNameFromContentDispostionHeader(contentDisposition?: string): string | undefined {
  if (contentDisposition === undefined) return;

  const standardPattern = /filename=(["']?)(.+)\1/i;
  const wrongPattern = /filename=([^"'][^;"'\n]+)/i;

  if (standardPattern.test(contentDisposition)) {
    return contentDisposition.match(standardPattern)?.[2];
  }

  if (wrongPattern.test(contentDisposition)) {
    return contentDisposition.match(wrongPattern)?.[1];
  }
}

function saveBlob(fileName: string, blob: Blob) {
  // MS Edge and IE don't allow using a blob object directly as link href, instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
    (window.navigator as any).msSaveOrOpenBlob(blob);
    return;
  }

  // For other browsers: create a link pointing to the ObjectURL containing the blob.
  const objUrl = window.URL.createObjectURL(blob);

  const link = document.createElement("a");
  link.href = objUrl;
  link.download = fileName;
  link.click();

  // For Firefox it is necessary to delay revoking the ObjectURL.
  setTimeout(() => {
    window.URL.revokeObjectURL(objUrl);
  }, 250);
}

interface Options {
  url: string;
  body?: BodyInit;
  onDownloadProgress?: (receivedLength: number, contentLength: number) => void;
  fetchOptions?: RequestInit | ((fetchOptions: RequestInit) => Promise<RequestInit>);
}

export async function downloadFile(options: Options) {
  const { url, onDownloadProgress, fetchOptions, body } = options;

  const tokenData = store.getState().auth.tokenData;

  let requestInit: RequestInit = {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      ...(tokenData ? { Authorization: `Bearer ${tokenData.token}` } : {}),
    },
    body,
  };

  if (typeof fetchOptions === "function") {
    requestInit = await fetchOptions(requestInit);
  } else if (typeof fetchOptions === "object") {
    requestInit = { ...requestInit, ...fetchOptions };
  }

  const response = await fetch(url, requestInit);

  if (!response.ok) {
    const responseBody = await response.text();
    throw new Error(responseBody ?? "Error try again");
  }

  const reader = response.body?.getReader();

  const contentLength = Number(response.headers.get("Content-Length"));

  let receivedLength = 0;
  const chunks = [];
  while (reader) {
    const { done, value } = await reader.read();

    if (done) break;

    chunks.push(value);
    receivedLength += value.length;

    if (typeof onDownloadProgress !== "undefined") {
      onDownloadProgress(receivedLength, contentLength);
    }
  }

  const type = response.headers.get("content-type")?.split(";")[0];

  // It is necessary to create a new blob object with mime-type explicitly set for all browsers except Chrome, but it works for Chrome too.
  const blob = new Blob(chunks, { type });

  const contentDisposition = response.headers.get("content-disposition");

  const fileName = getFileNameFromContentDispostionHeader(contentDisposition || undefined);

  return {
    fileName,
    blob,
  };
}

export interface DownloadAndSaveFileOptions extends Options {
  defaultFileName: string;
}

export interface DownloadAndSaveFileSuccess {
  success: true;
}

export interface DownloadAndSaveFileError {
  success: false;
  error: string;
}

export type DownloadAndSaveFileResult = DownloadAndSaveFileSuccess | DownloadAndSaveFileError;

export default async function downloadAndSaveFile(
  options: DownloadAndSaveFileOptions,
): Promise<DownloadAndSaveFileResult> {
  try {
    const { defaultFileName, ...rest } = options;

    const { fileName, blob } = await downloadFile(rest);

    await saveBlob(fileName ?? defaultFileName, blob);

    return { success: true };
  } catch (error) {
    console.log("DOWNLOAD ERROR", error);
    if (error instanceof Error) {
      return { success: false, error: error.message };
    } else {
      return { success: false, error: "Unknown error" };
    }
  }
}

export const CustomImageHandler = (quill: Quill | undefined) => {
  if (!quill) {
    console.error('Quill instance is not available');
    return;
  }

  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files?.[0];

    if (file) {
      const formData = new FormData();
      formData.append('image', file);

      try {
        const tokenData = store.getState().auth.tokenData;
        const response = await fetch(`${API_URL}/upload/image`, {
          method: 'POST',
          body: formData,
          headers: {
            ...(tokenData ? { Authorization: `Bearer ${tokenData.token}` } : {}),
          },
        });

        if (response.ok) {
          const {imageUrl, message} = await response.json();
          console.log("message: ", message);
          const range = quill.getSelection ? quill.getSelection() : 0;

          // Ensure that the quill instance is still valid before inserting
          if (range && quill.clipboard) {
            quill.clipboard.dangerouslyPasteHTML(
              range.index? range.index : 0,
              `<img src="${imageUrl}" alt=""/>`
            );
          }else{ 
            console.error('Quill instance is not valid');
          }
        } else {
          console.error('Image upload failed');
        }
      } catch (error) {
        console.error('Error uploading image:', error);
      }
    }
  };
};

export async function imageHandler(this: any): Promise<{link: string, file: File}>{
  const editor: Quill = this.editor;

  console.log("editor: ", editor.getContents());
  // console.log("selection: ", editor);

  // const editor = quillRef?.getEditor();

  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {

    if (input.files) {

      const file: File = input.files?.[0];
      const formData = new FormData();

      if (file) {
        const apiUrl = `${API_URL}/upload/image`;
        formData.append('image', file, file.name);
        const link = await uploadFile(apiUrl, formData);

        // try {
        //   if (editor) {
        //     const range = editor.getSelection();
        //     const value = editor.getContents();
        //     editor.insertEmbed(range?.index || 0, 'image', link);
        //     editor.setContents(value);
        //   }else{
        //     console.log("Editor not found");
        //   }
        // } catch (error) {
        //   console.log("UPLOAD ERROR", error);
        // }
        // console.log("value: ", value);
        return {link, file};
      }
    }

  }
  return {link: "", file: new File([""], "filename")};

}

export async function uploadFile(
  url: string,
  formData: FormData,
  onUploadProgress?: (uploadedLength: number, contentLength: number) => void,
): Promise<string> {
  const tokenData = store.getState().auth.tokenData;
  const response = await fetch(url, {
    method: "POST",
    headers: {
      ...(tokenData ? { Authorization: `Bearer ${tokenData.token}` } : {}),
    },
    body: formData,
  });

  if (!response.ok) {
    alert("The image couldn't be uploaded. \nError code: " + response.status + " - " + response.statusText);
    return "";
  }

  const {fileUrl, message} = await response.json();

  console.log(fileUrl, message);

  return fileUrl;
}