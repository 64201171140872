import React from "react";
import { Form, FormInstance, Input, message, Select, Switch } from "antd";
import ProfilePictureEditor from "../../components/ProfilePicture/ProfilePictureEditor";

export interface InputFormData {
    firstname: string;
    lastname: string;
    user_bio: string;
    profile_picture_url: string;
}

export interface OutputFormData {
    firstname: string;
    lastname: string;
    user_bio: string;
    profile_picture_url: string;
}

export function ProfileForm({
    form,
    initialValues,
}: {
    form: FormInstance<OutputFormData>;
    initialValues?: Partial<InputFormData>;
}) {
    const [content, setContent] = React.useState("");
    const quillRef = React.useRef<any>();

    return (
        <Form
            form={form}
            name="profile_form"
            layout="vertical"
            initialValues={initialValues}
        >
            <FirstnameInput />
            <LastnameInput />
            {/* <EmailInput /> */}
            <BioInput />
            <ProfilePicInput content={content} setContent={setContent} quillRef={quillRef}  />
        </Form>
    );
}

function FirstnameInput() {
    return (
        <Form.Item
            name="firstname"
            label="First Name"
            rules={[{ required: true, message: "Please enter first name!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function LastnameInput() {
    return (
        <Form.Item
            name="lastname"
            label="Lastname"
            rules={[{ required: true, message: "Please enter lastname!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function EmailInput() {
    return (
        <Form.Item
            name="email"
            label="Email"
            rules={[{ required: true, message: "Please enter email!" }]}
        >
            <Input />
        </Form.Item>
    );
}

function BioInput() {
    return (
        <Form.Item
            name="user_bio"
            label="Bio"
        >
            <Input />
        </Form.Item>
    );
}

interface ProfilePicProps {
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
    quillRef: any;
}

function ProfilePicInput({content, setContent, quillRef}:ProfilePicProps) {
    return (
        <Form.Item
            name="profile_picture_url"
            label="Profile Picture"
        >
            <ProfilePictureEditor initialValue={content} ref={quillRef} onChange={setContent} reference={quillRef} />
        </Form.Item>
    );
}
