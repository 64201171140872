import styled from "styled-components";
import { FaBars, FaUserCircle } from "react-icons/fa";
import { Button } from "../Button";

export const StyledNavbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2e6ea5;
  width: 100%;
  height: 60px;
  left: 0;
  right: 0;
`;

export const Bars = styled(FaBars)`
  color: #ffffff;
  display: block;
  font-size: 1.8rem;
  cursor: pointer;
  left: 18px;
  position: relative;
`;

export const UserIcon = styled(FaUserCircle)`
  color: #ffffff;
  display: block;
  position: relative;
  right: 18px;
  font-size: 1.8rem;
  cursor: pointer;
`;

export const WhiteCircle = styled.div`
  background-color: #ffffff;
  border-radius: 50%;
  max-width: 45px;
  min-width: 45px;
  positiion: relative;
  overflow: hidden;
  vertical-align: middle;
  display: inline-block;
  margin: 0 10px 0 0 ;
`;