import { Route, Routes } from "react-router-dom";
import Navbar from "../components/Navbar";
import AdminDashboard from "./Admin/AdminDashboard";
import AssignmentManager from "./Professor/Assignment/Manager";
import CourseManager from "./Professor/Course/Manager";
import CourseTemplateManager from "./Professor/CourseTemplate/Manager";
import ExpressionTest from "./Professor/ExpressionTest/ExpressionTests";
import TopicManager from "./Professor/Topic/Manager";
import { EntryType, LeftNavEntries } from "../components/Navbar/LeftSideNav/LeftSidePanel";
import CommonRouters from "./CommonRouters";
import UserManager from "./Professor/User/UserManager";

export default function AdminManager({ userIcon }: { userIcon?: string }) {
  const navEntries: LeftNavEntries[] = [
    {
      type: EntryType.LINK,
      title: "Courses",
      link: "/prof/course",
    },
    {
      type: EntryType.LINK,
      title: "Subjects",
      link: "/prof/course-template",
    },
    {
      type: EntryType.LINK,
      title: "Topic",
      link: "/prof/topic",
    },
    {
      type: EntryType.LINK,
      title: "Users",
      link: "/user",
    },
    {
      type: EntryType.LINK,
      title: "Math Playground",
      link: "math-expression-test",
    },
  ];

  return (
    <>
      <Navbar leftNavEntries={navEntries} userIcon={userIcon} />
      <CommonRouters />
      <Routes>
        <Route path="dashboard" element={<AdminDashboard />} />
        <Route path="math-expression-test" element={<ExpressionTest />} />
        <Route path="/prof/topic/*" element={<TopicManager canEdit canCreate />} />
        <Route
          path="/prof/course/*"
          element={
            <CourseManager
              canCreate
              canEdit
              canDelete
              canAddStudent
              canRemoveStudent
              canCreateAssignment
            />
          }
        />
        <Route
          path="/prof/course-template/*"
          element={<CourseTemplateManager canCreate canEdit canDelete />}
        />
        <Route path="/prof/assignment/*" element={<AssignmentManager />} />
        <Route path="/user/*" element={<UserManager />} />
      </Routes>
    </>
  );
}
